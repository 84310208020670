import React, { useState } from "react"
import { useForm } from "react-hook-form"
import Layout from "../components/Layout/Layout"
import styles from "./custom-graphics.module.css"
import Swal from "sweetalert2"
import { emailPattern, encode } from "../utils"
import { IoSendOutline } from "@react-icons/all-files/io5/IoSendOutline"
import Button from "../components/Button/Button"
import SEO from "../components/SEO/SEO"
import { graphql, useStaticQuery } from "gatsby"
import Image from "gatsby-image"

const CustomGraphicsPage: React.FC = () => {
  const { register, handleSubmit, errors, reset } = useForm()
  const [submitInProgress, setSubmitSubmitInProgress] = useState<boolean>(false)
  const {
    file: {
      childImageSharp: { fluid: rainbow },
    },
  } = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "rainbow.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const onError = () => {
    Swal.fire({
      title: "Oops. something gone wrong",
      text: "Please try again or contact us directly via hello@palapa.tech",
      icon: "error",
      confirmButtonText: "Close",
      confirmButtonColor: "#0019a3",
    })
  }
  const onSubmit = handleSubmit(data => {
    setSubmitSubmitInProgress(true)
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": "contact",
        name: data.name,
        company: data.company,
        email: data.email,
        description: data.description,
        phone: data.phone,
        contactAgreement: data.contactAgreement,
        marketingAgreement: data.marketingAgreement || false,
      }),
    })
      .then(e => {
        setSubmitSubmitInProgress(false)
        if (e.status === 200) {
          Swal.fire({
            title: "Thank you!",
            text:
              "We already wait can't for our journey begin! Expect an email from us within 2 business days",
            icon: "success",
            confirmButtonText: "Close",
            confirmButtonColor: "#0019a3",
          })
          reset()
          return
        }
        onError()
      })
      .catch(() => {
        setSubmitSubmitInProgress(false)
        onError()
      })
  })
  return (
    <>
      <SEO title="Custom graphics" />
      <Layout>
        <form
          data-sal="slide-right"
          name="contact"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          onSubmit={onSubmit}
          className={styles.form}
          method="post"
        >
          <div className={styles.titleImage}>
            <Image fluid={rainbow} className="hero-img" />
          </div>
          <div className={styles.title}>
            Need a custom 3d graphics for your Website, App, Blog post or any
            other needs?
          </div>
          <div className={styles.subtitle}>
            Leave us some information about your project and we will prepare an
            offer for you!
          </div>
          <input type="hidden" name="form-name" value="contact" />
          <div
            className={`${styles.control} ${
              errors.description ? styles.control__hasError : ""
            }`}
          >
            <div className="mm-form-label">Describe your project</div>
            <textarea
              rows={5}
              className="mm-form-input"
              ref={register({
                maxLength: {
                  value: 5000,
                  message:
                    "Opps. seems like your description is too long, please try todescribe it shorter",
                },
              })}
              name="description"
              placeholder="A couple words about your project"
            ></textarea>
            {errors.description && (
              <div className="mm-form-error-message">
                {errors.description.message || "Invalid value"}
              </div>
            )}
          </div>
          <div
            className={`${styles.control} ${
              errors.name ? styles.control__hasError : ""
            }`}
          >
            <div className="mm-form-label">Your name</div>
            <input
              className="mm-form-input"
              ref={register({
                maxLength: {
                  value: 500,
                  message: "Please try shorter name",
                },
              })}
              type="text"
              name="name"
              placeholder="John Doe"
            />
            {errors.name && (
              <div className="mm-form-error-message">
                {errors.name.message || "Invalid value"}
              </div>
            )}
          </div>
          <div
            className={`${styles.control} ${
              errors.email ? styles.control__hasError : ""
            }`}
          >
            <div className="mm-form-label">Email</div>
            <input
              className="mm-form-input"
              ref={register({
                maxLength: {
                  value: 500,
                  message: "Your email seems too long. Please try shorter one",
                },
                required: {
                  value: true,
                  message: "We need your email to be able to contact you back",
                },
                pattern: {
                  value: emailPattern,
                  message:
                    "Opps. seems like your email isn't correct. Please re-check it",
                },
              })}
              type="text"
              name="email"
              placeholder="john.doe@gmail.com"
            />
            {errors.email && (
              <div className="mm-form-error-message">
                {errors.email.message || "Invalid value"}
              </div>
            )}
          </div>
          <div
            className={`${styles.control} ${
              errors.company ? styles.control__hasError : ""
            }`}
          >
            <div className="mm-form-label">Company</div>
            <input
              className="mm-form-input"
              ref={register({
                maxLength: {
                  value: 100,
                  message: "Please try shorter company",
                },
              })}
              type="text"
              name="company"
              placeholder="My Awesome Company Inc."
            />
            {errors.company && (
              <div className="mm-form-error-message">
                {errors.company.message || "Invalid value"}
              </div>
            )}
          </div>
          <div
            className={`${styles.control} ${
              errors.phone ? styles.control__hasError : ""
            }`}
          >
            <div className="mm-form-label">Phone</div>
            <input
              ref={register({
                maxLength: {
                  value: 500,
                  message: "Please try shorter phone",
                },
              })}
              className="mm-form-input"
              type="text"
              name="phone"
              placeholder="+48 245 324 345"
            />
            {errors.phone && (
              <div className="mm-form-error-message">
                {errors.phone.message || "Invalid value"}
              </div>
            )}
          </div>
          <div
            className={`${styles.control} ${
              errors.contactAgreement ? styles.control__hasError : ""
            }`}
          >
            <div className="mm-form-label">
              I accept that Palapa sp. z o.o. will use my personal data in
              accordance to GDPR to:
            </div>
            <label className="mm-checkbox">
              <input
                ref={register({
                  required: {
                    value: true,
                    message: "This field is required",
                  },
                })}
                type="checkbox"
                name="contactAgreement"
              />
              Contact me regarding this enquiry
            </label>
            {errors.contactAgreement && (
              <div className="mm-form-error-message">
                {errors.contactAgreement.message || "Invalid value"}
              </div>
            )}
          </div>
          <div
            className={`${styles.control} ${
              errors.marketingAgreement ? styles.control__hasError : ""
            }`}
          >
            <label className="mm-checkbox">
              <input type="checkbox" name="marketingAgreement" />
              Keep me up to date with newsletters, offers and other marketing
              material
            </label>
            {errors.marketingAgreement && (
              <div className="mm-form-error-message">
                {errors.marketingAgreement.message || "Invalid value"}
              </div>
            )}
          </div>
          <div className={styles.button}>
            <Button size="large" type="submit" disabled={submitInProgress}>
              <IoSendOutline className={styles.buttonIcon} />
              Submit
            </Button>
          </div>
        </form>
      </Layout>
    </>
  )
}

export default CustomGraphicsPage
